import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery'; 
import { 
    addMovement,
    getMovements, 
    deleteMovement
} from 'redux-store/slices/movements';
import { 
    addWorkout,
    getWorkouts,
    getWorkoutDetails,
    deleteWorkout 
} from 'redux-store/slices/workouts';
import { 
    PageContent, 
    Row, 
    Col, 
    Button, 
    List, 
    ListItem, 
    Searchbar, 
    Block, 
    Popover, 
    Icon,
    SwipeoutActions,
    SwipeoutButton 
} from 'framework7-react';
import { concat, map, get, isEmpty, difference, filter, isArray, isObject } from 'lodash';
import logParameters from 'json/log-parameters.json';
import workoutTypes from 'json/workout-types.json';
import { renderTimecap } from 'misc/helpers.js';
import sanitizeHtml from 'sanitize-html';

import SelectWorkoutTypePopup from 'ui/comps/workout/SelectWorkoutTypePopup.js';
import WriteWorkoutPopup from 'ui/comps/workout/WriteWorkoutPopup.js';
import WorkoutPopup from 'ui/comps/workout/WorkoutPopup.js';
import PublicLibrary from 'ui/comps/library/PublicLibrary';

const Library = ({f7router}) =>  {
    
    const loadingMov = useSelector((state) => state.movements.loading);
    const allMovements = useSelector((state) => state.movements.all);
    const movUpdated = useSelector((state) => state.movements.updated);
    
    const loadingWorkout = useSelector((state) => state.workouts.loading);
    const singleWorkout = useSelector((state) => state.workouts.single);
    const allWorkouts = useSelector((state) => state.workouts.all);
    
    const dispatch = useDispatch();
    
    const [category, setCategory] = useState('all');
    
    const [currentWorkoutId, setWorkoutId] = useState(null);
    const [currentSingleWorkout, setSingleWorkout] = useState({type: 'sets'});
    
    const [currentListData, setListData] = useState([]);
    const [refreshLibrary, setRefreshLibrary] = useState(false);
    const initialDataCounter = useRef(0);
    const newlyAddedEntry = useRef(null);
    
        
    let isSwipping = false;
        
    useEffect(() => {
                
        if($('#library #search-results').length) {
            
            let listHeight = window.innerWidth < 768
                ? $('#main-toolbar').offset().top - $('#library #search-results').offset().top
                : window.innerHeight - $('#library #search-results').offset().top;
            
            $('#library #search-results').height(listHeight);
            
        }
                        
    }, [currentListData]);
    
    useEffect(() => {
                                
        if(['all', 'movements'].includes(category))
            dispatch( getMovements() );
            
        if(['all', 'workouts'].includes(category))
            dispatch( getWorkouts({is_library: true}) );
                            
    }, [category]);
    
    useEffect(() => {
        
        newlyAddedEntry.current = null;        
        let loadingText = loadingMov;
        if(loadingWorkout)
            loadingText = loadingWorkout;
                                
        f7router.app.dialog.close();
        if(loadingText) {
            f7router.app.dialog.preloader(loadingText)
        }
        
        //update list according to filter selection
        let listData = [];
        const currentMovements = filter(currentListData, 'log_parameter');
        const currentWorkouts = filter(currentListData, 'type');

        if(!isEmpty(currentListData)) {
            
            if(allMovements.length > currentMovements.length)
                newlyAddedEntry.current = difference(allMovements, currentMovements);
            else if(allWorkouts.length > currentWorkouts.length)
                newlyAddedEntry.current = difference(allWorkouts, currentWorkouts);
            
        }
        
        if(['all', 'movements'].includes(category))
            listData = concat(listData, allMovements);
            
        if(['all', 'workouts'].includes(category))
            listData = concat(listData, allWorkouts);
            
        //listData = [];
        
        if(!loadingMov && !loadingWorkout) {
            setListData(listData);
        }  
        
        if(typeof loadingText === 'string')
            initialDataCounter.current = initialDataCounter.current + 1;   
        
    }, [loadingMov, loadingWorkout]);
    
    useEffect(() => {
        
        if(!isEmpty(newlyAddedEntry.current)) {
            
            const $list = $('#search-results');
            const $listItem = $list.find('li[data-id="'+newlyAddedEntry.current[0].id+'"]');
            
            $listItem.addClass('new-added');
            $list.animate({
                scrollTop: $listItem.offset().top
            }, 200);
            
        }
        
    }, [currentListData]);
    
    useEffect(() => {
        
        setSingleWorkout(singleWorkout);
        
    }, [singleWorkout]);
    
    const doFilter = (evt) => {
                                        
        setCategory($('.smart-select [name="source_filter"]').val());
                
    }
    
    //------ MOVEMENTS
    
    const openMovSingle = (evt) => {
        
        if(isSwipping)
            return;
        
        const $listItem = $(evt.currentTarget).parents('li:first'); 
        if($listItem.hasClass('swipeout-opened')) {
            window.f7App.swipeout.close($listItem.get(0));
            return;
        } 
        
        const movementId = $listItem.data('id');
        $('#movement-popup').data('movid', movementId);
        f7router.app.popup.get('#movement-popup').open();
        
    }
    
    //------ WORKOUTS
    const workoutTypeSelect = (type) => {
        
        setWorkoutId(null);
        setSingleWorkout({type: type}); 

        f7router.app.popup.get('#workout-type-popup').close();
        f7router.app.popup.get('#add-edit-workout-popup').open();
        
    }
    
    const saveWorkout = (data) => {
        
        if(allWorkouts.length >= global.config.app.max_workouts_per_user) {
            window.f7App.toast.create(
                {text: `Right now only ${global.config.app.max_workouts_per_user} workouts per user are allowed!`}
            ).open();
            return;
        }
        
        data.is_library = true;
        dispatch( addWorkout({
            data: {...data}, 
            workout_id: currentWorkoutId, 
            is_library: true
        }) );
        
        newlyAddedEntry.current = data.name;
        
        f7router.app.popup.get('#add-edit-workout-popup').close();
        setWorkoutId(null);
        setSingleWorkout({});  
        
    }
    
    const openWorkSingle = (evt) => {
        
        if(isSwipping)
            return;
        
        const $listItem = $(evt.currentTarget).parents('li:first'); 
        if($listItem.hasClass('swipeout-opened')) {
            window.f7App.swipeout.close($listItem.get(0));
            return;
        }
        
        const workoutId = $listItem.data('id');
        
        setWorkoutId(workoutId);
        dispatch( getWorkoutDetails({id: workoutId}) );
        
        f7router.app.popup.get('#workout-popup').open();
        
    }
    
    const editWork = () => {
            
        f7router.app.popup.get('#workout-popup').close();
        f7router.app.popup.get('#add-edit-workout-popup').open();

    }
    
    const deleteWork = (id=null) => {
        
        dispatch( deleteWorkout({id: id ? id : currentWorkoutId, is_library: true}) );
        
        f7router.app.popup.get('#workout-popup').close();
        setWorkoutId(null);
        setSingleWorkout({});        
        
    }
    
    const renderWorkoutListItemFooter = (entry) => {
                
        const timeEl = renderTimecap(get(entry, 'time', 0), entry.type);
                              
        return (
            <span>{workoutTypes[entry.type].name} {timeEl ? ' - ' : ''} {timeEl}</span>
        )
        
    }
    
    const deleteEntry = (evt) => {
        
        const cat = $(evt.currentTarget).parents('li:first').data('cat'),
              id = $(evt.currentTarget).parents('li:first').data('id');
                
        window.f7App.dialog.confirm(
            `Are you sure to delete this ${cat === 'workout' ? 'workout' : 'movement'}?`,
            '',
            () => {
                if(cat === 'workout')
                    deleteWork(id);
                else
                    dispatch( deleteMovement({id: id}) );
            }
        )
        
    }
    
    const addPublicLibraryItem = (item) => {
        
        if(item.category == 'movement') {
            
            const movData = {
                name: item.name,
                log_parameter: item.log_parameter,
            };
            
            if(!isEmpty(item.description)) {
                movData.description = item.description;
            }
            
            if(isArray(item.video_urls)) {
                movData.video_urls = item.video_urls;
            }
            
            dispatch( addMovement({data: {...movData}}) );
        }
        else {
            
            const workoutData = {
                name: item.name,
                type: item.type,
                is_library: true,
            };
            
            if(!isEmpty(item.description)) {
                workoutData.description = item.description;
            }
            
            if(isArray(item.video_urls)) {
                workoutData.video_urls = item.video_urls;
            }
            
            if(item.time) {
                workoutData.time = item.time;
            }
            
            //sets
            if(isObject(item.setsData)) {
                workoutData.configs = item.configs;
                workoutData.setsData = item.setsData;
            }
            
            //amrap
            if(item.reps_per_round) {
                workoutData.reps_per_round = item.reps_per_round;
            }
            
            dispatch( addWorkout({data: {...workoutData}}) );
            
        }
        
        f7router.app.popup.close('#public-library-popup');
        
    }
    
    const renderWithoutContent = () => {
                
        return (
            <React.Fragment>
                <Row className="margin-top">
                    <Col>No movements or workouts in your personal library.</Col>
                </Row>
                <Row>
                    <Col>
                        <Button 
                            text="Create Movement"
                            small
                            fill
                            className="display-inline-block margin-top margin-right"
                            popupOpen="#movement-popup"
                        />
                        <Button 
                            text="Create Workout"
                            small
                            fill
                            className="display-inline-block margin-top"
                            popupOpen="#workout-type-popup"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )    
        
    }
    
    return (
        <PageContent>
            <Block>
                <Row>
                    <Col id="filter-area" width="85">
                        <List>
                        <ListItem title=" " smartSelect noChevron smartSelectParams={{ closeOnSelect: true, openIn: 'popover' }}>
                            <Icon slot="media" material="filter_list"></Icon>
                            <select name="source_filter" defaultValue="all" onChange={doFilter}>
                              <option value="all">Movements & Workouts</option>
                              <option value="movements">Movements</option>
                              <option value="workouts">Workouts</option>
                            </select>
                          </ListItem>
                        </List>
                    </Col>
                    <Col width="15" className="text-align-right">
                        <Button 
                            iconMaterial="add"
                            small
                            iconSize="30"
                            popoverOpen="#popover-add" 
                            className="display-inline-block no-padding"
                        />
                    </Col>
                </Row>
            </Block>
            
            <Block>
                <Searchbar
                    inline
                    searchContainer=".search-list"
                    searchIn=".search-parameters"
                    disableButton={false}
                />
                {initialDataCounter.current >= 2 && isEmpty(currentListData) &&
                    renderWithoutContent()
                }
                <List className="search-list" id="search-results">
                    {map(currentListData, (entry, i) => {
                        
                        let searchParameters = entry.name;
                        if(entry.type && entry.description) {
                            
                            //add workout description to search parameters, replace html with space and replace multiple spaces with one
                            searchParameters += ' '+entry.description.replace(/<\/?[^>]+(>|$)/g, " ").replace(/\s\s+/g, ' ');
                            
                        }
                        
                        return (
                            <ListItem 
                                title={entry.name} 
                                footer={entry.type 
                                        ? renderWorkoutListItemFooter(entry) 
                                        : logParameters[entry.log_parameter]
                                }
                                link="#"
                                data-id={entry.id}
                                data-cat={entry.type ? 'workout' : 'movement'}
                                onClick={(evt) => entry.type ? openWorkSingle(evt) : openMovSingle(evt)}
                                onSwipeoutOpen={() => isSwipping=true}
                                onSwipeoutOpened={() => isSwipping=false}
                                key={entry.id}
                                swipeout
                            >
                                <Icon 
                                    slot="media" 
                                    material={entry.type ? 'ballot' : 'sports_gymnastics'}
                                />
                                <SwipeoutActions right>
                                    <SwipeoutButton color="red" onClick={deleteEntry}>
                                        Delete
                                    </SwipeoutButton>
                                </SwipeoutActions>
                                <span className="hidden search-parameters">
                                    {searchParameters}
                                </span>
                            </ListItem>
                        )
                    })}
                </List>
            </Block>
            
            <Popover id="popover-add">
                <List>
                    <ListItem
                        title="Add From Public Library"
                        link="#" 
                        noChevron 
                        popoverClose 
                        popupOpen="#public-library-popup"
                    />
                    <ListItem
                        title="Create Movement"
                        link="#" 
                        noChevron 
                        popoverClose 
                        popupOpen="#movement-popup"
                    />
                    <ListItem
                        title="Create Workout"
                        link="#" 
                        noChevron 
                        popoverClose 
                        popupOpen="#workout-type-popup"
                    />
                </List>
            </Popover>
            
            <SelectWorkoutTypePopup 
                workoutTypeSelect={workoutTypeSelect}
            />
            <WriteWorkoutPopup                
                {...currentSingleWorkout}
                add={saveWorkout}
            />
            <WorkoutPopup
                {...currentSingleWorkout}
                edit={editWork}
                delete={deleteWork} 
            />
            
            <PublicLibrary
                addItem={addPublicLibraryItem}
            />
                          
        </PageContent> 
    )
    
}

export default Library;
