import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    Popup, 
    Page, 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button,
    BlockHeader,
    Toolbar,
    Tabs,
    Tab,
    Link,
    Icon,
    Popover,
    Input
} from 'framework7-react';
import { isEmpty, get, map } from 'lodash';
import { renderTimecap, sanitizeDesc,renderWorkoutTypeInfo } from 'misc/helpers.js';
import moment from 'moment';
import $ from 'jquery';
import axios from 'axios';

import ModalActions from 'ui/comps/global/ModalActions';
import WorkoutTracking from './WorkoutTracking';
import SetsDisplay from './SetsDisplay';
import EmbeddedVideo from 'ui/comps/global/EmbeddedVideo';

const WorkoutPopup = (props) =>  {
    
    const [shareLink, setShareLink] = useState('');
    
    useEffect(() => {
        
        if(props.id) {
            
            if(process.env.NODE_ENV !== 'development') {
                
                const apiKey = process.env.REACT_APP_IS_STAGE ? 'AIzaSyBYErPPYH7_w3D9mZihi58w4LgZ5XO0TFU' : 'AIzaSyBbJFYK8F2B-HoI9pnqnyO6y0k7gZ-UY_c';
                const domain = process.env.REACT_APP_IS_STAGE ? 'https://stage.xesh.fit' : 'https://app.xesh.fit';
                const longShareLink = domain+'/link/workout/'+props.id;
                
                axios.post(
                    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key='+apiKey, 
                    {
                        dynamicLinkInfo: {
                            domainUriPrefix: domain+'/share',
                            link: longShareLink
                        },
                        suffix: {
                            option: "SHORT"
                        }
                    }
                )
                .then((res) => {
                    
                    if(get(res, 'data.shortLink', null)) 
                        setShareLink(res.data.shortLink);
                    else 
                        setShareLink(longShareLink);
                        
                })
                .catch((err) => {
                    
                    if(props.id)
                        setShareLink(longShareLink);
                    
                })
                
            }
            else {
                
                setShareLink(window.location.origin+'/link/workout/'+props.id);
                
            }
            
        }
                
    }, [props.id])
    
    const popupOpen = () => {
        
        const $toolbar = $('#workout-popup .toolbar.tabular');
        $toolbar.find('.tab-link').removeClass('tab-link-active').first().addClass('tab-link-active'); 
        $toolbar.next('.tabs').children('.tab').removeClass('tab-active').first().addClass('tab-active'); 
        
    }
        
    const deleteWorkout = () => {
            
        window.f7App.dialog.confirm(
            'Are you sure to delete this workout?',
            '',
            () => {
                props.delete();
            }
        )
        
    }
    
    const copyShareLink = () => {
                
        navigator.clipboard.writeText($('#popover-share-link input').val());
        window.f7App.toast.create(
            {text: 'Copied to clipboard'}
        ).open();
        
    }
    
    const renderVideos = (videoUrls) => {
                
        return (
            <Block className="videos-slider">
                <div className="margin-top horizontal-scroll">
                    {map(videoUrls, (videoUrl, i) => {
                                                    
                        return (
                            <div key={videoUrl+i}>
                                <EmbeddedVideo url={videoUrl} />
                            </div>
                        )
                        
                    })}
                </div>
            </Block>
        )
        
    }
    
    return (
        
        <Popup
            id="workout-popup"
            onPopupOpen={popupOpen}
        >
            <Page>
                <Block>
                    <Row>
                        <Col width="65">
                            <BlockTitle large>
                                {props.name}
                            </BlockTitle>
                            <BlockHeader>
                                {renderWorkoutTypeInfo(props)}
                                {renderTimecap(get(props, 'time', 0), props.type)}
                            </BlockHeader>
                        </Col>
                        <ModalActions 
                            id = "modal-actions-workout" 
                            width = "35"
                            onEdit = {props.edit}
                            onDelete = {deleteWorkout}
                        >
                            <Button 
                                iconMaterial="ios_share"
                                fill
                                small
                                tooltip="Get Share Link"
                                popoverOpen="#popover-share-link"
                            />
                        </ModalActions>
                        <Popover id="popover-share-link">
                            <Block>
                                <BlockHeader>Share your workout</BlockHeader>
                                <div className="input-action">
                                    <Input 
                                        type="text"
                                        readyonly
                                        onFocus={(evt) => evt.currentTarget.select()}
                                        value={shareLink}
                                    />
                                    <Button 
                                        iconMaterial="content_copy"
                                        fill
                                        small
                                        onClick={copyShareLink}
                                    />
                                </div>
                            </Block>
                        </Popover>
                    </Row>
                </Block>
                <div>
                    <Toolbar tabbar scrollable className="tabular">
                        <Link 
                            text="Tracking"
                            tabLink="#workout-tracking"
                            tabLinkActive
                        />
                        <Link 
                            text="Details"
                            tabLink="#workout-details"
                        />
                    </Toolbar>
                    <Tabs className="margin">
                        <Tab id="workout-tracking" tabActive>
                            <WorkoutTracking
                                workoutId = {props.id}
                                workoutType = {props.type}
                                repsPerRound = {get(props, 'reps_per_round', 0)}
                            />
                        </Tab>
                        <Tab id="workout-details">
                            {/* strip tags to check if description contains some content */}
                            {!isEmpty(get(props, 'description', '').replace(/<\/?[^>]+(>|$)/g, "")) &&
                                <Block inset className="description no-padding">
                                    <p dangerouslySetInnerHTML={{__html: sanitizeDesc(props.description)}}></p>
                                </Block> 
                            }
                            {!isEmpty(get(props, 'video_urls', [])) &&
                                renderVideos(get(props, 'video_urls', []))
                            }
                            {props.type === 'sets' &&
                                <SetsDisplay 
                                    configs = {props.configs}
                                    setsData = {props.setsData}
                                />
                            }
                            <div className="notice">
                                You can execute this workout by adding it to a session in your Planner.
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                
            </Page>
        </Popup>
        
    )
    
}

WorkoutPopup.propTypes = {
    type: PropTypes.string,
}

WorkoutPopup.defaultProps = {
    type: 'sets'
}

export default WorkoutPopup;