import {firebaseApp, auth} from './init';
import {
    getFirestore,
    collection,
    doc,
    getDoc
} from 'firebase/firestore';

const db = getFirestore(firebaseApp);
const publicLibraryCol = collection(db, 'public_library');

export const readPlWorkout = (id) => {
    
    const docRef = doc(db, publicLibraryCol.path, id);
    return getDoc(docRef);
    
};