import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import algoliasearch from 'algoliasearch';
import { InstantSearch, RefinementList, Pagination, Menu, ClearRefinements } from 'react-instantsearch-hooks-web';
import { 
    Popup,
    Page,
    Block,
    ListItem,
    BlockTitle,
    Col,
    Row,
    Panel,
    Button,
    BlockHeader
} from 'framework7-react';
import $ from 'jquery'; 
import { get } from 'lodash';
import workoutTypes from 'json/workout-types.json';

import ModalActions from 'ui/comps/global/ModalActions';
import SearchBox from 'ui/comps/algolia/SearchBox';
import PublicLibraryHits from 'ui/comps/algolia/PublicLibraryHits';


function PublicLibrary(props) {
    
    const [popupOpened, setPopupOpened] = useState(false);
    
    const searchClient = algoliasearch(
            global.config._algoliaCreds.appId, 
            global.config._algoliaCreds.apiKey
    );
    
    const transformWorkoutTypes = (items) => {
        
        return items.map((item) => ({
            ...item,
            label: workoutTypes[item.label].name,
         }));
        
    }
    
    return (
        <Popup
            id="public-library-popup"
        >
            <Page>
                <Block>
                    <Row>
                        <Col width="80">
                            <BlockTitle large>
                                Public Library
                            </BlockTitle>
                        </Col>
                        <ModalActions 
                            id = "modal-actions-public-library" 
                        />
                    </Row>
                </Block>
                <Block id="algolia-wrapper">
                    <InstantSearch searchClient={searchClient} indexName="public_library">
                        
                        <Row>
                            <Col width="90">
                                <SearchBox />
                            </Col>
                            <Col width="10">
                                <Button
                                    iconMaterial="filter_list"
                                    className="no-padding"
                                    small
                                    panelOpen="#public-library-filters"
                                />
                                
                            </Col>
                        </Row>
                        
                        <PublicLibraryHits addItem={props.addItem} />
                        <Pagination
                            classNames = {{
                                root: 'pagination-menu',
                                list: 'segmented',
                                item: 'button button-small button-outlin',
                                disabledItem: 'disabled'
                            }}
                        />
                        <Panel
                            id="public-library-filters"
                            containerEl="#public-library-popup"
                            swipe
                            swipeOnlyClose
                        >
                            <Page>
                                <Block>
                                    <BlockHeader>Category</BlockHeader>
                                    <Menu 
                                        attribute="category"
                                        sortBy={['count:desc']}
                                        classNames = {{
                                            item: 'chip chip-outline',
                                        }}
                                    />
                                </Block>
                                <Block>
                                    <BlockHeader>Training Methodology</BlockHeader>
                                    <RefinementList 
                                        attribute="sports"
                                        sortBy={['count:desc']}
                                        classNames = {{
                                            root: 'list',
                                            item: '',
                                            label: '',
                                            checkbox: 'input'
                                        }}
                                    />
                                </Block>
                                <Block>
                                    <BlockHeader>Tags</BlockHeader>
                                    <RefinementList 
                                        attribute="type"
                                        sortBy={['count:desc']}
                                        classNames = {{
                                            root: 'list no-margin-bottom',
                                            item: '',
                                            label: '',
                                            checkbox: 'input'
                                        }}
                                        transformItems={transformWorkoutTypes}
                                    />
                                    <RefinementList 
                                        attribute="tags"
                                        sortBy={['count:desc']}
                                        classNames = {{
                                            root: 'list',
                                            item: '',
                                            label: '',
                                            checkbox: 'input'
                                        }}
                                    />
                                </Block>
                                <Block>
                                    <ClearRefinements
                                        translations={{
                                            resetButtonText: 'Clear Filters',
                                        }}
                                        classNames={{
                                            root: 'display-inline-block',
                                            button: 'button button-outline button-small',
                                        }}
                                    />
                                </Block>
                                
                            </Page>
                        </Panel>
                    </InstantSearch>
                </Block>
                
            </Page>
        </Popup>
        
    )
}

PublicLibrary.propTypes = {
    addItem: PropTypes.func
}

export default PublicLibrary

