import React from 'react';
import { 
    Block, 
    Button,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
} from 'framework7-react';
import { isEmpty, get } from 'lodash';
import { renderTimecap, sanitizeDesc } from 'misc/helpers.js';
import workoutTypes from 'json/workout-types.json';

import SetsDisplay from 'ui/comps/workout/SetsDisplay';

const SessionWorkout = (props) => {
    
    const timeEl = renderTimecap(get(props, 'time', 0), props.type);
        
    const renderWorkout = () => {
                    
        return (
            <React.Fragment>
            {!isEmpty(get(props, 'description', '')) &&
                <div dangerouslySetInnerHTML={{__html: sanitizeDesc(props.description)}} className="workout-description"></div>
            }
            {props.type === 'sets' &&
                <SetsDisplay 
                    configs = {props.configs}
                    setsData = {props.setsData}
                />
            }
            </React.Fragment>
        )
        
    }
    
    return (
        
        <Card>
            <CardHeader>
                <div className="float-left">
                    <span className="workout-name">{props.name}</span>
                    <span className="workout-info">{workoutTypes[props.type].name} {timeEl ? ' - ' : ''} {timeEl}</span>
                </div>
                <div className="float-right">
                    <Button 
                        className="move-up"
                        iconMaterial="keyboard_double_arrow_up"
                        tooltip="Move Up"
                        onClick={() => props.moveWorkout('up', props.id)}
                     />
                    <Button 
                        className="move-down"
                        iconMaterial="keyboard_double_arrow_down"
                        tooltip="Move Down"
                        onClick={() => props.moveWorkout('down', props.id)}
                     />
                     {!props.is_library &&
                         <Button 
                             iconMaterial="edit"
                             tooltip="Edit Workout"
                             onClick={() => props.editWorkout(props.id)} 
                         />
                     }
                    <Button 
                        iconMaterial="delete_outline"
                        tooltip="Remove Workout" 
                        color="red"
                        onClick={() => props.removeWorkout(props.id)}
                    />
                </div>
            </CardHeader>
            <CardContent>
                {renderWorkout()}
            </CardContent>
        </Card>
        
    )
    
}

export default SessionWorkout;