import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "redux-store/store";
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from "capacitor-ios-app-tracking";
import GitInfo from 'react-git-info/macro';
import './configs.js';
import 'fb-api/emulator';

import Root from './ui/Root';
import AppUrlListener from './ui/comps/global/AppUrlListener';

import 'styles/css/f7/framework7-bundle.css';
import 'styles/css/f7/framework7-icons.css';
import 'styles/sass/main.scss';



let isProd = process.env.NODE_ENV !== 'development';
//isProd = true;

window._xesh_env = {
    isStage: process.env.REACT_APP_IS_STAGE == 1,
    honeybadgerEnabled: isProd,
    env: process.env.NODE_ENV
}
//console.log("ENV", window._xesh_env);

Framework7.use(Framework7React);

const rootElement = document.getElementById("app");
const root = createRoot(rootElement);
const platform = Capacitor.getPlatform();

const addMatomo = () => {
    
    var _paq = window._paq = window._paq || [];
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
        var u="https://appxeshfit.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '1']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='//cdn.matomo.cloud/appxeshfit.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
    })();
    
    window._xesh_env.matomoEnabled = true;

}

if(platform === 'ios') {
    
    AppTrackingTransparency.requestPermission().then((status) => {
        if(status.status === 'authorized') {
            addMatomo();
        }
    });
       
}
else if(platform === 'web' && isProd) {
    
    const script = document.createElement('script');
    script.id = "cookieyes";
    script.src = 'https://cdn-cookieyes.com/client_data/5eb40ce8d60577c461febb01/script.js';
    document.head.appendChild(script);
    
    addMatomo();
    
}

if(isProd) {
    
    global.config._algoliaCreds = {
        appId: '9ZSNEHE75E',
        apiKey: 'aabe27ca82226b6dea84a5185a56b7c8'
    };
    
    const gitInfo = GitInfo();
    const honeybadgerConfig = {
        apiKey: 'hbp_Ubcks08dxFX9lfhIxJVCpU2fVjBy3D4mJgMk',
        environment: process.env.NODE_ENV,
        revision: gitInfo.commit.hash,
        reportData: true
    }
    
    const honeybadger = Honeybadger.configure(honeybadgerConfig);
    window._honeybadger = honeybadger;
    
    root.render(
        <HoneybadgerErrorBoundary honeybadger={honeybadger}>
            <Provider store={store}>
                <Root />
                <AppUrlListener />
            </Provider>
        </HoneybadgerErrorBoundary>
    );
    
}
else {
    
    //prod
    global.config._algoliaCreds = {
        appId: '9ZSNEHE75E',
        apiKey: 'aabe27ca82226b6dea84a5185a56b7c8'
    };
    
    //stage
    // global.config._algoliaCreds = {
    //     appId: '9ODV99HLQC',
    //     apiKey: '33c5b3524761653f4994fc04f8b040e5'
    // };
    // 
    //local
    global.config._algoliaCreds = {
        appId: '5NNMBVE4NK',
        apiKey: 'c2ac3e1c28fa94cc00afc2e2c34167ad'
    };
    
    
    
   root.render(
       <Provider store={store}>
           <Root />
           <AppUrlListener />
       </Provider>
   );
   
}