import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import $ from 'jquery'; 
import { 
    List,
    ListItem, 
    Icon,
    Segmented,
    Button,
    Popup,
    Block,
    BlockTitle,
    BlockHeader,
    Row,
    Col,
    Page,
    Popover,
    Input
} from 'framework7-react';
import { useHits } from 'react-instantsearch-hooks-web';
import { map, get } from 'lodash';
import workoutTypes from 'json/workout-types.json';
import logParameters from 'json/log-parameters.json';
import { renderTimecap, renderWorkoutTypeInfo, sanitizeDesc } from 'misc/helpers.js';
import axios from 'axios';

import ModalActions from 'ui/comps/global/ModalActions';
import EmbeddedVideo from 'ui/comps/global/EmbeddedVideo';

function PublicLibraryHits(props) {
    
    const { hits, results, sendEvent } = useHits(props);
    const [itemDetails, setItemDetails] = useState(null);
    const [shareLink, setShareLink] = useState('');
    
    useEffect(() => {
        
        if(itemDetails) {
            
            if(process.env.NODE_ENV !== 'development') {
                
                const apiKey = process.env.REACT_APP_IS_STAGE ? 'AIzaSyBYErPPYH7_w3D9mZihi58w4LgZ5XO0TFU' : 'AIzaSyBbJFYK8F2B-HoI9pnqnyO6y0k7gZ-UY_c';
                const domain = process.env.REACT_APP_IS_STAGE ? 'https://stage.xesh.fit' : 'https://app.xesh.fit';
                const longShareLink = domain+'/link/workout/'+itemDetails.objectID+'?pl';
                
                axios.post(
                    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key='+apiKey, 
                    {
                        dynamicLinkInfo: {
                            domainUriPrefix: domain+'/share',
                            link: longShareLink
                        },
                        suffix: {
                            option: "SHORT"
                        }
                    }
                )
                .then((res) => {
                    
                    if(get(res, 'data.shortLink', null)) 
                        setShareLink(res.data.shortLink);
                    else 
                        setShareLink(longShareLink);
                        
                })
                .catch((err) => {
                    
                    if(itemDetails.objectID)
                        setShareLink(longShareLink);
                    
                })
                
            }
            else {
                
                setShareLink(window.location.origin+'/link/workout/'+itemDetails.objectID+'?pl');
                
            }
            
        }
        
    }, [itemDetails])
    
    const viewItemDetails = (entry) => {
        
        setItemDetails(entry);
        window.f7App.popup.open('#public-library-item-details-popup');
        
    }
    
    const copyShareLink = () => {
                
        navigator.clipboard.writeText($('#popover-pl-share-link input').val());
        window.f7App.toast.create(
            {text: 'Copied to clipboard'}
        ).open();
        
    }
    
    const renderWorkoutListItemFooter = (entry) => {
                
        const timeEl = renderTimecap(get(entry, 'time', 0), entry.type);
                              
        return (
            <span>{workoutTypes[entry.type].name} {timeEl ? ' - ' : ''} {timeEl}</span>
        )
        
    }
    
    const renderVideos = (videoUrls) => {
                
        return (
            <Block className="videos-slider">
                <div className="margin-top horizontal-scroll">
                    {map(videoUrls, (videoUrl, i) => {
                                                    
                        return (
                            <div key={videoUrl+i}>
                                <EmbeddedVideo url={videoUrl} />
                            </div>
                        )
                        
                    })}
                </div>
            </Block>
        )
        
    }
    
    return (
        <React.Fragment>
        
            <List>
                <ul>
                    {map(hits, (entry, i) => {
                        return (
                            <ListItem 
                                title={entry.name} 
                                footer={entry.type 
                                        ? renderWorkoutListItemFooter(entry) 
                                        : logParameters[entry.log_parameter]
                                }
                                noChevron
                                link="#"
                                onClick={(evt) => viewItemDetails(entry)}
                                key={entry.objectID}
                            >
                                <Icon 
                                    slot="media" 
                                    material={entry.type ? 'ballot' : 'sports_gymnastics'}
                                    tooltip={entry.category.toUpperCase()}
                                />
                                <Button 
                                    small 
                                    fill
                                    type="button" 
                                    iconMaterial="add"
                                    onClick={(evt) => {
                                        props.addItem(entry);
                                        evt.stopPropagation();
                                    }}
                                />
                            </ListItem>
                        )
                    })}
                </ul>
            </List>
            
            <Popup 
                id="public-library-item-details-popup"
                onPopupClosed={() => setItemDetails(null)}
            >
                {itemDetails && 
                    <Page>      
                        <Block>
                            <Row>
                                <Col width="80">
                                    <BlockTitle large>{itemDetails.name}</BlockTitle>
                                    {get(itemDetails, 'type', false) &&
                                        <BlockHeader>
                                            {renderWorkoutTypeInfo(itemDetails)}
                                            {renderTimecap(get(itemDetails, 'time', 0), itemDetails.type)}
                                        </BlockHeader>
                                    }
                                    {itemDetails.log_parameter &&
                                        <BlockHeader>
                                            {logParameters[itemDetails.log_parameter]}
                                        </BlockHeader> 
                                    }
                                </Col>
                                <ModalActions 
                                    id = "modal-actions-public-library-item-details" 
                                >
                                    {itemDetails.category == 'workout' &&
                                        <Button 
                                            iconMaterial="ios_share"
                                            fill
                                            small
                                            tooltip="Get Share Link"
                                            popoverOpen="#popover-pl-share-link"
                                        />
                                    }
                                    
                                </ModalActions>
                                <Popover id="popover-pl-share-link">
                                    <Block>
                                        <BlockHeader>Share this workout</BlockHeader>
                                        <div className="input-action">
                                            <Input 
                                                type="text"
                                                readyonly
                                                onFocus={(evt) => evt.currentTarget.select()}
                                                value={shareLink}
                                            />
                                            <Button 
                                                iconMaterial="content_copy"
                                                fill
                                                small
                                                onClick={copyShareLink}
                                            />
                                        </div>
                                    </Block>
                                </Popover>
                            </Row>
                        </Block>
                        {get(itemDetails, 'description', false) &&
                            <Block inset className="description no-padding">
                                <p dangerouslySetInnerHTML={{
                                    __html: sanitizeDesc(itemDetails.description)}}>
                                </p>
                            </Block>
                        }
                        {renderVideos(itemDetails.video_urls)}  
                    
                    </Page> 
                }
            </Popup>
            
        </React.Fragment>
    )
}

PublicLibraryHits.propTypes = {
    addItem: PropTypes.func
}

export default PublicLibraryHits