import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { renderTimecap, sanitizeDesc } from 'misc/helpers.js';
import { readWorkout, writeWorkout } from 'fb-api/workouts';
import { readMovement, writeMovement } from 'fb-api/movements';
import { readPlWorkout } from 'fb-api/public-library';
import { getUser, signInUser } from 'fb-api/users';
import { 
    Page, 
    Block, 
    Row, 
    Col, 
    Button, 
    BlockTitle, 
    BlockHeader, 
    Popup, 
    Card,
    CardHeader,
    CardContent,
    CardFooter 
 } from 'framework7-react';
import { isEmpty, get, map, each, uniq, has, cloneDeep, pick } from 'lodash';
import { renderWorkoutTypeInfo } from 'misc/helpers';

import SetsDisplay from './SetsDisplay';
import SignIn from 'ui/comps/sign/SignIn.js';
import Logo from 'ui/comps/global/Logo';

const ViewWorkout = props => {
        
    const [user, setUser] = useState(null);
    const [workoutData, setWorkoutData] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        
        props.f7router.app.popup.close('.popup', false);
        
        (async function() {
            
            setUser(await getUser());
            
            let docRef;
            //read from public library
            if(has(props, 'f7router.currentRoute.query.pl')) {
                docRef = await readPlWorkout(props.id);
            }
            //read from user library
            else {
                docRef = await readWorkout(props.id);
            }
            
            setWorkoutData(docRef.data() ? docRef.data() : false);
            
            setLoading(false);    
            
        })();
        
        
    }, []);
    
    const openSignIn = () => {
        
        props.f7router.app.popup.open('#view-workout-sign-in');
        
    }
    
    const signHandler = async (email, password) => {
        
        props.f7router.app.preloader.showIn('.login-screen');
        
        const res = await signInUser(email, password); //returns user object on success
        
        if(res.error) {
            props.f7router.app.toast.create({text: res.error}).open()
        }
        else {
            setUser(res);
            props.f7router.app.popup.close('#view-workout-sign-in');
        }
        
        props.f7router.app.preloader.hideIn('.login-screen');
    }
    
    const createMovementsFromSets = (setsData) => {
        
        return new Promise((resolve, reject) => {
            
            let uniqueMovs = [];
            
            //loop through sets
            each(setsData, (set) => {
                
                //loop through movements in set
                each(set,(movEntry) => {
                    uniqueMovs.push(movEntry.movement.id);
                })
                
            });
            
            uniqueMovs = uniq(uniqueMovs);
            
            new Promise((resolve, reject) => {
                
                let newMovements = [];
                each(uniqueMovs, async (movId, index) => {
                    
                    const movDoc = await readMovement(movId);
                    
                    let movData = movDoc.data();
                    delete movData.created_at;
                    delete movData.user_id;
                    
                    const newMovDoc = await writeMovement({data: movData});
                    
                    if(newMovDoc.id) {
                        //key=old doc id, value=new mov id
                        newMovements[movId] = newMovDoc.id;
                    }
                    
                    if(index == uniqueMovs.length-1 ) {
                        resolve(newMovements);
                    }
                    
                })
                
            })
            .then((newMovements) => {
               
               
               each(setsData, (set) => {
                   
                   //loop through movements in set
                   each(set,(movEntry) => {
                       movEntry.movement.id = newMovements[movEntry.movement.id];
                   })
                   
               });
               
               resolve(setsData);
                
            })
            
        })
        
    }
    
    const addWorkout = async () => {
        
        const allowedKeys = [
            'name', 
            'type', 
            'description', 
            'time', 
            'configs', 
            'setsData', 
            'reps_per_round',
            'video_urls'
        ];
        
        let data = cloneDeep(workoutData);
        data = pick(data, allowedKeys)
        
        data.is_library = true;
                
        if(data.setsData) {
            
            createMovementsFromSets(data.setsData)
            .then(async (setsData) => {
                
                data.setsData = setsData;
                
                try {
                    
                    const docRef = await writeWorkout({data: data});
                    props.f7router.navigate('/athlete/library/');
                    
                }
                catch(e) {
                   props.f7router.app.toast.create({text: e.message}).open()
                }
                
            })
            
        }
        else {
            
            try {
                
                await writeWorkout({data: data});
                props.f7router.navigate('/athlete/library/');
                
            }
            catch(e) {
               props.f7router.app.toast.create({text: e.message}).open()
            }
            
        }
        
    }
    
    const relevantSignedContent  = () => {
                
        if(user) {
            return (
                <React.Fragment>
                    <Button fill medium onClick={addWorkout}>
                        Add to my library
                    </Button>
                    <p style={{textAlign: 'center'}}><a href="/athlete/">Go to my account</a></p>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Button fill medium onClick={openSignIn}>
                        Sign In To Add Workout
                    </Button>
                    <p style={{textAlign: 'center'}}>New to this app? <a href="/signup">Create a new account</a></p>
                </React.Fragment>
            )
            
        }
        
    }
    
    return (
        <Page id="view-workout">
            <Logo id="logo" />
            <div style={{textAlign: 'center'}}>
                <a href="https://xesh.fit" className="external" target="_blank">What is XESH?</a>
            </div>
            {!loading && workoutData !== false &&
                <Block>
                    <Card outline>
                        <CardHeader className="text-align-center">
                                Add this workout to your XESH library         
                        </CardHeader>
                        <CardHeader>
                            {workoutData.name}
                            <div className="meta">
                                {renderWorkoutTypeInfo(workoutData)}
                                {renderTimecap(get(workoutData, 'time', 0), workoutData.type)}
                            </div>
                        </CardHeader>
                        <CardContent>
                            {!isEmpty(get(workoutData, 'description', '').replace(/<\/?[^>]+(>|$)/g, "")) &&
                                <Block inset className="description no-padding no-margin">
                                    <p dangerouslySetInnerHTML={{__html: sanitizeDesc(workoutData.description)}}></p>
                                </Block> 
                            }
                            {workoutData.type === 'sets' &&
                                <SetsDisplay 
                                    configs = {workoutData.configs}
                                    setsData = {workoutData.setsData}
                                />
                            }
                        </CardContent>
                        <CardFooter>
                            {relevantSignedContent()}
                        </CardFooter>
                    </Card>
                </Block>
            }
            {workoutData === false &&
                <div>
                    <p className="notice notice-error margin-top">
                        The workout does not exist anymore.
                    </p>
                    <p style={{textAlign: 'center'}}><a href="/signin">Go to Homepage</a></p>
                </div>
            }
            <Popup id="view-workout-sign-in">
                <Page>
                    <Block>
                        <SignIn signHandler={signHandler} f7router={props.f7router} />
                    </Block>
                </Page>
            </Popup>
        </Page>
    )
}

ViewWorkout.propTypes = {

}

export default ViewWorkout
